import React, {useState} from 'react'
import clubbieLogo from '../../assets/images/clubbie_logo_full.svg'
import backButton from '../../assets/images/back_button.svg'
import { AUTHENTICATION_STACK } from '../../utils/screenNames'
import { useNavigate } from 'react-router-dom';
import { Button } from 'reactstrap';
import './RegisterWithEmail.css';
import {Input} from 'reactstrap';
import { validatePassword } from '../../utils/helpers';
import { useLocation } from 'react-router-dom';
import { EyeOutlined, EyeInvisibleOutlined } from '@ant-design/icons';
import { resetAccountPassword } from '../../lib/authActions';
import PopUpWithMsg from '../../components/PopUpWithMsg';

function ResetPasswordWithEmail() {

  const navigate = useNavigate()
  const [resetFailed, setResetFailed] = useState(false)
  const [resetPasswordCode, setResetPasswordCode] = useState(false)
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false);
  const [resetSuccessPopUp, setResetSuccessPopUp] = useState(false)

  const location = useLocation()
  const {email} = location?.state

  const [warningMsg, setWarningMsg] = useState("")

  const goBack = () => {
    navigate(-1)
  }

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  }

  const resetPassword = async () => {
    function onSuccess(){
        setResetSuccessPopUp(true)
    }

    function onFail(){
        setResetFailed(true)
    }

    const passwordErr = validatePassword(password)
    if(passwordErr){
        setWarningMsg(passwordErr)
    }
    else if (resetPasswordCode.length===6){
        await resetAccountPassword(email, null, password, resetPasswordCode, onSuccess, onFail)
    }
  }

  const closeSuccessPopUp = () => {
    setResetSuccessPopUp(false)
    navigate(AUTHENTICATION_STACK.LOGIN_WITH_EMAIL)
  }


  return (
    <div className="app-container" >
      
      <div className='auth-top-bar'>
        <img className='back-button' src={backButton} alt="hello" onClick={goBack}/> 
        <img className="clubbie-logo" src={clubbieLogo} alt="hello"/>
      </div>

      <div className='welcome-text-box'>
        <p className="welcome-text">Reset password</p>
        <p className="welcome-msg"> Enter the code we sent you!</p>
      </div>

      <div className='button-container-email-register'>

        {resetFailed && <p className='info-warning'>Password reset failed.</p>}

        <Input required type='email' placeholder={email} disabled={true} className='mb-4 email-input' ></Input>
        <Input required type='number' onChange={(event)=>{setResetPasswordCode(event.target.value)}} 
                placeholder='Reset password code' value={resetPasswordCode} className='mb-4 email-input'></Input>

        <div className='password-input'>
          <Input
            type={showPassword ? "text" : "password"}
            name="password"
            id="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="mb-5 email-input"
          />
          <Button 
            type="button" 
            onClick={togglePasswordVisibility} 
            className="password-toggle-button"
          >
            {showPassword ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </Button>
        </div>

        {warningMsg && <p className='info-warning'>{warningMsg}</p>}

        <Button 
            className='register-button'
            onClick={resetPassword}>
            Reset Password   
        </Button>

        {resetSuccessPopUp && <PopUpWithMsg popUpMsg={"Password changed successfully!"} justMsg={true} closePopUp={closeSuccessPopUp}/>}

      </div>
  </div>
  )
}


export default ResetPasswordWithEmail